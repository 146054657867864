<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox class="mt-1"
                         :dataValue="item.id"
                         v-if="item.status !== PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"
        />
        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInfoPopup')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('payments_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('payments_Total.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              {{item.user_amount}}
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('payments_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TooltipTextHelper
                      :text="item.payment_transaction_detail.description"
                      :paragraph="item.payment_transaction_detail.description"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('payments_Status.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('common_Paid.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
              {{$t('common_InProgress.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                        item.status === PAYMENTS_STATUSES.ERROR_STATUS">
              <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
              {{$t('common_Rejected.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
              <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
              {{$t('common_statusNew.localization_value.value')}}
            </div>
          </div>
        </div>
        <div class="table-card__item-btn pl-2">
          <div v-if="item.status !== PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS" class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_Pay.localization_value.value')"
                    :type="'dollar'"
                    @click.native="$emit('payPayment')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.financeNewPayment + '/edit/' + item.id"
            />
          </div>
          <div v-if="item.status !== PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS" class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removePayment')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "NewPaymentsTableMobile",
    components: {
      TooltipTextHelper,
      TableUserColumn,
      DefaultCheckbox,
      StatusIcoBtn,
      LinkButton
    },

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    },


  }
</script>

<style scoped>

</style>
