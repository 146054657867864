<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo
              :pm="pm"
          />
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.financeNewPayment" class="detail-page__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'payments_NewPayment',
                        ])"></div>
              {{$t('payments_NewPayment.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'payments_NewPaymentDecs',
                        ])"></div>
              {{$t('payments_NewPaymentDecs.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <UserSelect
                        :serverError="serverError"
                        :validationUser="pm.validation.user"
                        :validationTxtUser="pm.validationTxt.user"
                        :validationTranslateUser="pm.validationTranslate.user"
                        :userSelected="pm.newPayments.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                        :disabled="pm.newPayments.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'common_Amount',
                        ])"></div>
                <!--<InputQuantity-->
                    <!--:label="'Amount'"-->
                    <!--v-bind:class="{'ui-no-valid': pm.validation.amount}"-->
                    <!--:errorTxt="serverError ? pm.validationTxt.amount : $t(`${pm.validationTranslate.amount}.localization_value.value`)"-->
                    <!--:error="pm.validation.amount"-->
                    <!--v-model="pm.newPayments.amount"-->
                    <!--@change="changeValueQuantity"-->
                <!--/>-->
                <InputSum
                        :label="$t('common_Amount.localization_value.value')"
                        :icoType="'dollar'"
                        @change="changeValueQuantity"
                        :placeholder="'0'"
                        v-bind:class="{'ui-no-valid': pm.validation.amount}"
                        :errorTxt="serverError ? pm.validationTxt.amount : $t(`${pm.validationTranslate.amount}.localization_value.value`)"
                        :error="pm.validation.amount"
                        :value="pm.newPayments.amount"
                        :disabled="pm.newPayments.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"
                />
              </div>
            </div>
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'payments_comment',
                        ])"></div>
                <TextareaDefault
                        :label="$t('payments_comment.localization_value.value')"
                    :placeholder="'Please be careful with the vase inside!'"
                    v-bind:class="{'ui-no-valid': pm.validation.comment}"
                    :errorTxt="serverError ? pm.validationTxt.comment : $t(`${pm.validationTranslate.comment}.localization_value.value`)"
                    :error="pm.validation.comment"
                    v-model="pm.newPayments.comment"
                />
              </div>
            </div>
          </form>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeNewPayment"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_update',
                          'common_Pay',
                        ])"></div>

              <MainButton
                      class="order-create__footer-btn-i wfc mr-1"
                      :value="$t('common_Pay.localization_value.value')"
                      @click.native="$emit('pay')"
                      v-if="pm.newPayments.status !== PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"
                      v-bind:class="{'disabled-btn' : $store.getters.getNewPaymentsBtn}"
              />

              <MainButton
                      class="order-create__footer-btn-i wfc"
                      :value="$t('common_update.localization_value.value')"
                      @click.native="$emit('updatePayment')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNewPaymentsBtn}"
              />

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  // import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../staticData/staticVariables";

  export default {
    name: "NewPaymentsEditAdmin",
    components: {
      InputSum,
      UserSelect,
      MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
      // InputQuantity,
      TextareaDefault,
      // DefaultSelect,
    },

    props: {
      pm: Object,
      serverError: Boolean,
    },

    data(){
      return{
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    },

    methods:{
      changeUser(user) {
        this.pm.setUser(user)
      },

      changeValueQuantity(data) {
        this.pm.setAmount(data)
      },
    },

  }
</script>

<style scoped>

</style>
