<template>
  <NewPaymentsCreateAdmin
          :pm="pm"
          :serverError="serverError"
          @createPayment="createPayment"
  />
</template>

<script>
  import NewPaymentsCreateAdmin from "./NewPaymentsCreateAdmin/NewPaymentsCreateAdmin";
  import {NewPayments} from "../../NewPayments";
  import {newPaymentsMixin} from "../../../../../mixins/newPaymentsMixins/newPaymentsMixin";

  export default {
    name: "NewPaymentsCreate",

    components: {
      NewPaymentsCreateAdmin,
    },

    mixins: [newPaymentsMixin],

    data() {
      return {
        pm: new NewPayments(),
        serverError: false,

      }
    },

    mounted() {

    },

    methods: {


    },

  }
</script>

<style scoped>

</style>
