<template>
  <div>
    <NewPaymentsHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @changeFilter="data => {$emit('changeFilter', data)}"
        @massPay="$emit('massPay')"
    />

    <div class="table-filter-wrap">

      <NewPaymentsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => {$emit('changeFilter', data)}"
          @resetFilter="$emit('resetFilter')"
      />

      <NewPaymentsTable
          :countFilterParams="countFilterParams"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @showMore="$emit('showMore')"
          @filter="$emit('filter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import NewPaymentsHead from "./NewPaymentsHead/NewPaymentsHead";
  import NewPaymentsFilter from "./NewPaymentsFilter/NewPaymentsFilter";
  import NewPaymentsTable from "./NewPaymentsTable/NewPaymentsTable";

  export default {
    name: "NewPaymentsTableUser",

    components: {
      NewPaymentsHead,
      NewPaymentsFilter,
      NewPaymentsTable,
    },

    props: {
      filterGetParams: Object,
      countFilterParams: Number,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
