<template>
  <NewPaymentsTableAdmin
          @filter="filter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @resetFilter="resetFilter"
          @downloadFiles="downloadFiles"
          @massPay="massPay"
          @reload="reload"
  />
</template>

<script>
  import NewPaymentsTableAdmin from "./NewPaymentsTableAdmin/NewPaymentsTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {newPaymentsMixin} from "../../../../../mixins/newPaymentsMixins/newPaymentsMixin";

  export default {
    name: "NewPaymentsTable",

    components: {
      NewPaymentsTableAdmin,
    },

    mixins: [routeFilter, newPaymentsMixin],

    data() {
      return {
        forPage: this.$store.getters.getNewPaymentsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterOrder: '',
        filterUserId: '',
        filterUserName: '',
        filterTotal: '',
        filterComment: '',
        filterStatus: 'success,on_hold',

        filterGetParams: {},
        countFilterParams: 0,
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          order: this.$route.query.order,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          total: this.$route.query.total,
          comment: this.$route.query.comment,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextNewPaymentsPage', true)

        // save filter params in store
        this.$store.commit('setNewPaymentsFilter', window.location.search)


        this.$store.dispatch('fetchNewPayments', url).then(() => {
          // skip get next page
          this.$store.commit('setNextNewPaymentsPage', false)

          if(this.$route.query.payId){
            this.payPayment(this.$route.query.payId, {goToTable: false}).then(() => {
              let query = Object.assign({}, this.$route.query);
              delete query.payId;
              this.$router.replace({ query });
            })
          }
        })

        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterOrder: 'PaymentId',
            // filterTotal: 'PaymentTransactionPaymentTotal',
            filterUserId: 'PaymentTransactionUserId',
            filterComment: 'comment',
            filterStatus: 'PaymentTransactionStatus',
          },
        )

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        // If generate on export file
        if(exportIds && exportIds.length > 0) {
          myQuery.whereIn('PaymentId', exportIds)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      massPay() {
        let exportIds

        exportIds = this.getCheckedRows('row-name')
        if(exportIds.length > 0){
          exportIds.map(item => {
            this.payPayment(item, {goToTable: false})
          })
        }

      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportNewPayments', {filter: url, type: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.createDownloadLink({data: response.data, name: `New Payments.${type}`, type: type})
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

    },

  }
</script>

<style scoped>

</style>
