var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page reverse"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{staticClass:"clear-992",attrs:{"backgroundImage":'product-admin'}},[_c('template',{slot:"body"},[_c('UserInfo',{attrs:{"pm":_vm.pm}})],1)],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col"},[_c('router-link',{staticClass:"detail-page__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.financeNewPayment}},[_c('LinkBack',{attrs:{"value":_vm.$t('ex_backTo.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'payments_NewPayment',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('payments_NewPayment.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__head-txt mb-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'payments_NewPaymentDecs',
                      ])}}}),_vm._v(" "+_vm._s(_vm.$t('payments_NewPaymentDecs.localization_value.value'))+" ")])])]),_c('template',{slot:"body"},[_c('form',{staticClass:"order-create__section"},[_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('UserSelect',{attrs:{"serverError":_vm.serverError,"validationUser":_vm.pm.validation.user,"validationTxtUser":_vm.pm.validationTxt.user,"validationTranslateUser":_vm.pm.validationTranslate.user,"userSelected":_vm.pm.newPayments.user,"typeSelect":'users',"userEmail":true,"disabled":_vm.pm.newPayments.status === _vm.PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS},on:{"changeUser":_vm.changeUser}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_Amount',
                      ])}}}),_c('InputSum',{class:{'ui-no-valid': _vm.pm.validation.amount},attrs:{"label":_vm.$t('common_Amount.localization_value.value'),"icoType":'dollar',"placeholder":'0',"errorTxt":_vm.serverError ? _vm.pm.validationTxt.amount : _vm.$t(`${_vm.pm.validationTranslate.amount}.localization_value.value`),"error":_vm.pm.validation.amount,"value":_vm.pm.newPayments.amount,"disabled":_vm.pm.newPayments.status === _vm.PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS},on:{"change":_vm.changeValueQuantity}})],1)]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"custom-col custom-col--66 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'payments_comment',
                      ])}}}),_c('TextareaDefault',{class:{'ui-no-valid': _vm.pm.validation.comment},attrs:{"label":_vm.$t('payments_comment.localization_value.value'),"placeholder":'Please be careful with the vase inside!',"errorTxt":_vm.serverError ? _vm.pm.validationTxt.comment : _vm.$t(`${_vm.pm.validationTranslate.comment}.localization_value.value`),"error":_vm.pm.validation.comment},model:{value:(_vm.pm.newPayments.comment),callback:function ($$v) {_vm.$set(_vm.pm.newPayments, "comment", $$v)},expression:"pm.newPayments.comment"}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link"},[_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.financeNewPayment}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")])],1),_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                        'common_update',
                        'common_Pay',
                      ])}}}),(_vm.pm.newPayments.status !== _vm.PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS)?_c('MainButton',{staticClass:"order-create__footer-btn-i wfc mr-1",class:{'disabled-btn' : _vm.$store.getters.getNewPaymentsBtn},attrs:{"value":_vm.$t('common_Pay.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('pay')}}}):_vm._e(),_c('MainButton',{staticClass:"order-create__footer-btn-i wfc",class:{'disabled-btn' : _vm.$store.getters.getNewPaymentsBtn},attrs:{"value":_vm.$t('common_update.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('updatePayment')}}})],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }