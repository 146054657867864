<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{getNewPaymentsItem.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'payments_NewPayment',
          'common_Amount',
          'payments_comment',
        ])"></span>
        <div class="site-link brown text-decoration-underline">
          <div v-if="isAdmin && getNewPaymentsItem.activityUUID"
               class="mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
            <div class="site-link text-decoration-underline btn-style brown"
                 @click="historyOfChangesFunc('newPayments', getNewPaymentsItem.activityUUID)">
              {{$t('common_CheckHistory.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="transaction-info__name">
          {{getNewPaymentsItem.user.contacts[0].user_full_name}}
        </div>
        <div class="transaction-info__name"
             v-if="item = getNewPaymentsItem"
        >
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
            {{$t('common_InProgress.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
            {{$t('common_Paid.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">
            <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
            {{$t('common_Rejected.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
            <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
            {{$t('common_statusNew.localization_value.value')}}
          </div>
        </div>

        <div class="transaction-info__section">

          <div class="transaction-info__row">
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('payments_Created.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('common_Amount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{getNewPaymentsItem.payment_total}}
              </div>
            </div>
            <div class="custom-col" v-if="getNewPaymentsItem.payment_transaction_detail.description">
              <div class="transaction-info__label">
                {{$t('payments_comment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{getNewPaymentsItem.payment_transaction_detail.description}}
              </div>
            </div>
            <div class="custom-col"
                 v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
              <div class="transaction-info__label">
                {{$t('cuspayments_SKLADUSAFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{item.warehouse_commission_amount}}
                <span class="site-link site-link--light brown text-decoration-underline clear-after"
                      v-if="Number(item.warehouse_commission_amount) > 0" @click="addCommission(item.id, false)">
                  {{$t('cuspayments_SKLADUSAFeeRemove.localization_value.value')}}
                </span>
                <span class="site-link site-link--light brown text-decoration-underline clear-after"
                      v-else @click="addCommission(item.id, true)">
                  {{$t('cuspayments_SKLADUSAFeeAdd.localization_value.value')}}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";
  import {mapGetters} from "vuex";


  export default {
    name: "InfoIDPopup",
    components: {
      StatusIcoBtn,
      Modal,
    },

    computed: {
      ...mapGetters([
        'getNewPaymentsItem',
      ])
    },

    data() {
      return{
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    },

    methods: {
      addCommission(id, val) {
        let data = {
          paymentTransactionId: id,
          processType: val ? 1 : 0,
        }
        this.$store.dispatch('changeWarehouseCommission', data).then(response => {
          if(!response.data.data.status){
            this.openNotify('error', 'common_notificationUndefinedError')
          }
          else {
            this.openNotify('success', 'common_notificationStatusChanged')
            this.$emit('reload')
            this.$emit('close')
          }
        })
      },
    },
  }

</script>

<style lang="scss">

</style>
